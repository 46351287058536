<template>
    <div class="not-found">
        <center><h1>404 Not Found.</h1></center>
        <hr />
        <center>vue-router</center>
    </div>
</template>

<script>
export default {
    name: "app",
    components: {},
};
</script>

<style scoped>
</style>